body {
	background-color: black;
	background-image: radial-gradient(rgba(0, 150, 0, 0.75), black 120%);
	height: 100vh;
	margin: 0;
	overflow: auto;
	padding: 2rem;
	color: white;
	font: 1.3rem Inconsolata, monospace;
	text-shadow: 0 0 5px #C8C8C8;
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: repeating-linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) 1px, transparent 1px, transparent 2px);
		pointer-events: none;
	}
}
&::selection {
	background: #0080FF;
	text-shadow: none;
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.Typist {
	.Cursor {
		display: inline-block;
	}
	.Cursor--blinking {
		opacity: 1;
		animation: blink 1s linear infinite;
	}
}

.Text--blinking { 
	opacity: 1;
	animation: blink 1s linear infinite;
}

